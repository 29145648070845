<template lang='pug'>
  #refer.view
    refer-form( v-if='user && profile' :user='user' :profile='profile' )
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'
  import ReferForm from '@/components/forms/refer'

  export default {
    name: 'refer.view',

    mixins: [ Viewable ],
    
    props: {
      user: { default: () => ({}) },
      profile: { default: () => ({}) },
    },

    components: {
      ReferForm
    },

    data() {
      return {
      }
    },

    // firestore() {
    //   return {
    //   }
    // },

    methods: {
    }
  }
</script>

<style scoped lang='stylus'>
</style>

