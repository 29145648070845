import { render, staticRenderFns } from "./refer.vue?vue&type=template&id=5dc38aca&scoped=true&lang=pug&"
import script from "./refer.vue?vue&type=script&lang=js&"
export * from "./refer.vue?vue&type=script&lang=js&"
import style0 from "./refer.vue?vue&type=style&index=0&id=5dc38aca&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dc38aca",
  null
  
)

export default component.exports