<template lang="pug">
  #refer.view
    header
      w-wrapper
        w-flex
          h4.title {{ $t('Refer to Friends')}}
    section.refer
      w-wrapper.mainbody
        form( @submit.prevent='submit' )
          label Subject
          input( type='text' v-model='reference.subject' )

          label Send From
          input( type='text' v-model='reference.from' required )

          w-flex.checkbox
            input( type='checkbox' v-model='reference.follow' )
            span Automatically follow invited users

          label Invite (fill in email addresses separated by commas)
          textarea( v-model='reference.emails' required )

          label Message
          textarea( v-model='reference.message' )
          
          w-flex.centered
            w-button( type='submit' ).wide
              template( v-slot:text ) {{ $t('Send Invitation') }}
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'

  export default {
    name: 'refer.form',

    mixins: [ Viewable ],

    data() {
      return {
        reference: { profile: this.profile }
      }
    },

    methods: {
      submit() {
        db.collection( 'referrals' ).add( this.reference ).then( () => this.reference = {} )
      }
    }
  }
</script>

<style scoped lang="stylus">
  #refer
    .w-wrapper
      padding 10px 18px 10px 18px
    .mainbody
      background rgba( 255 255 255 .95)
      border-radius 5px
      padding 50px
  
  header
    margin-top -50px
    background rgba( 80 160 210 .95 )
    color white  
    @media( max-width: 420px)
      font-size: .8em
      padding 0px

  section
    background rgba( 0 0 0 .5 )
    padding 20px

  form
    input, label, textarea
      display block
    input, textarea
      background rgb( 255 255 255 )
      border-radius 5px
      margin-bottom 32px
      padding 8px 12px

    label
      margin-bottom 8px
    input
      min-width 320px
    .checkbox
      align-items center
      margin 32px 0
      input
        margin 0
        min-width 0
      span
        margin-left 8px
</style>
